import React from 'react'
const GrpLoader = () => {
  return (
<>
    <div className="d-flex justify-content-center align-items-center grp-loader">
      <svg viewBox='0 0 400 160'>
        <text x="50%" y="50%" dy={"0.32rem"} textAnchor='middle' className='text-body'>
          GRP
        </text>
      </svg>
    </div>
</>
  )
}

export default GrpLoader