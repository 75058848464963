import "bootstrap/dist/css/bootstrap.min.css";
import React, { Suspense, lazy, useState, useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Navibar from "./Common/Navibar/Navibar";
import Footer from "./Common/Footer/Footer.jsx";
import { HelmetProvider } from 'react-helmet-async';
import SeoHelmet from "./Components/SeoHelmet/SeoHelmet.jsx";
import GrpLoader from "./Common/GrpLoader/GrpLoader.jsx";
import Loader from "./Common/Loader/Loader.jsx";

// Lazy load components
const Home = lazy(() => import("./Components/Home/Home.jsx"));
const How = lazy(() => import("./Components/How/How.jsx"));
const Pricing = lazy(() => import("./Components/Pricing/Pricing.jsx"));
const FreqAsked = lazy(() => import("./Components/FreqAsked/FreqAsked.jsx"));
const Terms = lazy(() => import("./Components/Terms/Terms.jsx"));
const Policy = lazy(() => import("./Components/Policy/Policy.jsx"));
const NotFound = lazy(() => import("./Components/NotFound/NotFound.jsx"));
const IntakeForm = lazy(() => import("./Components/IntakeForm/IntakeForm.jsx"));

function App() {
  const [showHome, setShowHome] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowHome(true);
    }, 4000); // 4 seconds

    return () => clearTimeout(timer);
  }, []);
  return (
    <>
    <HelmetProvider>
      <SeoHelmet />
      <BrowserRouter>
      <Navibar />
        <Suspense fallback={<Loader/>}>
          <Routes>
            <Route path="/" element={showHome ? <Home /> : <GrpLoader />} />
            <Route path="/how" element={<How />} />
            <Route path="/pricing" element={<Pricing />} />
            <Route path="/freqasked" element={<FreqAsked />} />
            <Route path="/terms" element={<Terms />} />
            <Route path="/policy" element={<Policy />} />
            <Route path="/intake" element={<IntakeForm />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Suspense>
        <Footer />
      </BrowserRouter>
      </HelmetProvider>
    </>
  );
}

export default App;
